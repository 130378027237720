body {
  background-color: #fafafa !important;
}

@font-face {
  font-family: "PSL Kamda Bold";
  src: url("../../../Fonts/PSLxImperial/PSLxImperial\ Bold.ttf");
}

@font-face {
  font-family: "PSL Kamda";
  src: url("../../../Fonts/PSLxImperial/PSLxImperial.ttf");
}

.ant-confirm-body .ant-confirm-title {
  line-height: normal !important;
}

.Content {
  display: flex;
}

span.ant-confirm-title {
  font-size: 15px !important;
}

p {
  margin-bottom: 2px !important;
}
.crop-name-event {
  display: flex;
  justify-content: center;
  width: 100%;
}
.show-name-event {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 90%;
}
.font-bib {
  font-size: 18px;
  font-weight: bold;
  color: orange;
  padding-top: 10px;
}
.Content1 {
  margin-top: 10px;
}
.Part-Content {
  margin-top: 10px;
}
.font-bib1 {
  font-size: 25px;
  color: #fff;
  margin-top: 2px;
  font-family: "PSL Kamda";
}
.font-close {
  font-size: 17px;
  font-weight: bold;
  color: orange;
  text-align: center;
}
.ant-form-item {
  margin-bottom: 0px !important;
}

.modelImg {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Imgcontent {
  width: 150px;
  text-align: center;
}

.modelLink {
  margin-top: 5px;
}

.ant-input-number-handler-wrap {
  display: none;
}

@media only screen and (max-width: 992px) {
  div.head-page {
    height: 400px;
  }
  div.head-page:before {
    height: 400px;
  }
}

@media only screen and (max-width: 768px) {
  div.head-page {
    height: 400px;
  }
  div.head-page:before {
    height: 400px;
  }
  .font-amountrun {
    color: orange;
    font-weight: bold;
    font-size: 70px;
  }
  .tel {
    display: none;
  }
  .imgpopup {
    max-height: 220px;
  }
  .imageItem {
    width: 80px;
    height: 150px;
  }
}
@media (max-width: 782px) {
  .header1 {
    height: 30px;
    min-height: 45px;
  }
}
@media screen and (max-width: 700px) {
  .ant-dropdown-menu {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 600px) {
  .ant-dropdown-menu {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 425px) {
  .header1 {
    height: 50px !important;
    min-height: 40px !important;
    position: fixed;
  }
  .ant-dropdown-menu {
    margin-top: 45px !important;
  }
  div.head-page {
    display: none;
  }
  .font-amountrun {
    color: orange;
    font-weight: bold;
    font-size: 40px;
  }
  .font-bahtrun {
    font-size: 17px;
    padding-top: 20px;
  }
  .font-slug {
    font-size: 20px !important;
  }
}
