@font-face {
  font-family: Metropolis;
  src: url(Metropolis-Regular.ttf);
}
@font-face {
  font-family: SukhumvitSet-Light;
  src: url(SukhumvitSet-Light.ttf);
}
@font-face {
  font-family: SukhumvitSet-Bold;
  src: url(SukhumvitSet-Bold.ttf);
}

body {
  font-family: "Metropolis";
}

.footer {
  bottom: 0px;
  font-family: "Metropolis";
  margin: 0px auto;
}

.footer-text {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: white;
}
.ant-upload.ant-upload-select {
  width: 100% !important;
}
.ant-radio-button-wrapper-checked::before {
  background-color: #000 !important;
  opacity: 0.1;
}
.fbShareBtn {
  background-color: #3a559f;
  border-color: #3a559f;
  color: white;
  padding: 15px;
}
