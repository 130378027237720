/* new css */

div.head-page-home {
  width: 1250px;
  height: 310px;
  background: -webkit-linear-gradient(
    bottom,
    #1683ca,
    #1683ca 86px,
    #1b93ed 20px,
    #1b93ed
  );
  background-size: cover;
  background-position: center;
  display: flex;
  max-width: 100%;
  margin-top: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.head-page-home:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 1250px;
}

.text-in-header1 {
  color: white;
  margin-top: 15px;
  font-size: 17px;
  font-weight: bold;
}
.text-in-header2 {
  color: white;
  font-size: 30px;
  font-weight: bold;
}
.text-in-header3 {
  color: white;
  margin-top: 22px;
  font-size: 15px;
  font-weight: bold;
}
.search-box {
  margin-top: 12% !important;
  width: 90% !important;
}

@media screen and (max-width: 991px) {
  div.head-page-home {
    height: 456px;
  }
  .text-in-header1 {
    margin-top: 10%;
  }
  .text-in-header3 {
    margin-top: 5px;
  }
  .search-box {
    margin-top: -10px !important;
    margin-bottom: 22px !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 575px) {
  div.head-page-home {
    height: 323px;
    background: -webkit-linear-gradient(
      bottom,
      #1683ca,
      #1683ca 20%,
      #1b93ed 30px,
      #1b93ed
    );
  }
}

/* old css ===============================================*/

.textSectionHeader {
  margin-left: 4px;
  font-size: 25px !important;
  color: #000;
  /* border-bottom: 6px solid #f9a01e; */
  width: 100%;
  max-width: 450px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup_inner {
  position: absolute;
  width: 500px;
  max-width: 100%;
  margin: auto;
  background: white;
  border-radius: 4px;
}
.Content {
  padding-top: 3%;
}
.font-bib {
  font-size: 18px;
  font-weight: bold;
  color: orange;
}
.close {
  font-size: 16px;
  font-weight: bold;
  color: #666;
}
button.ant-btn.btn-close {
  border: none !important;
  border-radius: 0px 4px 0px 0px !important;
  padding: 5px 20px !important;
  opacity: 0.6;
  height: 50px !important;
}
button.ant-btn.btn-close:hover {
  opacity: 1;
}
.close-popup {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #e9e9e9;
}
.button-Cancel {
  padding: 10px 20px 10px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  border-top: 1px solid #e9e9e9;
  width: 100%;
}
.btn-Cancel1 {
  margin-right: 10px !important;
  border-radius: 20px !important;
  font-weight: bold !important;
}
.btn-Cancel1:hover {
  background-color: #40a9ff !important;
  color: #fff !important;
}
div.head-page {
  width: auto;
  height: 500px;

  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.head-page:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 1250px;
  height: 500px;
}
.text-head {
  color: #fff;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
  font-weight: bold;
  font-size: 60px;
  padding: 0px 20px;
  z-index: 9;
  font-family: "Lato", sans-serif;
}

/* ------------------------------ScrollOnTop--------------------- */
.sctop {
  position: fixed;
  z-index: 3;
  width: 3%;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  border-radius: 90px;
  transition: all 0.2s;
}
.sctop:hover {
  opacity: 1;
}
.sctopon {
  opacity: 0.8;
}

/*................END...............*/

/* ------------------------------Navbar--------------------- */

.nav-test {
  float: right;
  border: 0 !important;
}
.nav-test1 {
  display: none;
  font-size: 35px;
  line-height: 100px;
  cursor: pointer;
  transition: color 0.3s;
}
.font-nav {
  color: rgb(121, 120, 120);
}
.logo {
  height: 100px;
  justify-content: center;
}
.logo-top {
  margin-top: 15px;
}
.ant-dropdown {
  width: 100% !important;
  top: 4.3% !important;
  position: fixed !important;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: "#fff";
  -webkit-box-shadow: 0 12px 34px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 34px rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 34px rgba(0, 0, 0, 0.12);
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255);
  height: 90px;
  min-height: 85px;
  position: fixed;
  top: 0;
  z-index: 999;
  -webkit-transition: all 0.5s ease;
  -o-transition: 0.5s all ease;
  transition: all 0.5s ease;
  width: 100%;
  padding: 0;
}
.header1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.87);
  height: 90px;
  min-height: 85px;
  position: fixed;
  top: 0;
  z-index: 999;
  -webkit-transition: all 0.5s ease;
  -o-transition: 0.5s all ease;
  transition: all 0.5s ease;
  width: 100%;
  padding: 0;
}
.nav-test {
  float: right;
  border: 0 !important;
}
.button-nav {
  display: inline-block;
  padding: 10px;
  font-size: 16px;
  margin: 10px;
  margin-right: 0px;
  border: solid #00000000 !important;
  font-family: "Kanit";
}

.main {
  color: rgb(41, 127, 240) !important;
  transition: all 0.5s;
}
.Secondary {
  color: rgb(202, 202, 202) !important;
}
.Secondary:hover {
  color: rgb(41, 127, 240) !important;
}
.Secondary2 {
  color: rgb(189, 188, 188) !important;
}
/*................END...............*/

/*-----------------------SlideBanner------------------------*/
.ant-carousel .slick-slide {
  text-align: center;
  height: 100%;
  line-height: 100%;
  background: transparent;
  overflow: hidden;
}
.picslide {
  margin-top: 90px;
  width: 100%;
}
/*................END...............*/

/*-----------------------DetailEvent------------------------*/
.pic3step {
  width: 25%;
}
.verticalline1 {
  left: 22%;
  position: absolute;
  width: 6%;
  height: 120px;
  transition: width 2s;
  top: 41%;
}
.verticalline-Full {
  left: 22%;
  position: absolute;
  width: 50%;
  height: 120px;
  transition: width 2s;
  top: 41%;
}
.verticalline2 {
  position: absolute;
  background: rgb(114, 187, 255);
  width: 100%;
  height: 1px;
  z-index: -999;
}
.font3step {
  color: rgb(116, 116, 116);
  font-family: "Kanit";
}
.first-work1 {
  text-align: center;
}
.first-work2 {
  text-align: center;
  width: 100%;
}
.cardact {
  width: 100%;
}
.cardact-dt {
  width: 25%;
  display: inline-block;
}

/*................END...............*/

/*-----------------------DetailVTrun------------------------*/
.area-vt {
  width: 80%;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: rgba(0, 0, 0, 0.13);
}
.pic-vtrun {
  width: 100%;
}
.vtrun-zone1 {
  width: 45%;
  float: left;
  margin-left: 4%;
}
.vtrun-zone2 {
  padding-top: 5%;
  width: 45%;
  float: left;
  word-wrap: break-word;
  margin-right: 3%;
}
.text-vtrun-head {
  font-size: 30px;
}
.text-vtrun {
  font-size: 15px;
  text-indent: 40px;
}
/*................END...............*/

/* ------------------------QueryData-------------------------------- */
.event-text {
  text-align: left;
  font-size: 16px;
  color: rgb(70, 70, 70);
}

.event-textmonth {
  font-size: 14px;
  text-align: center;
  color: orange;
}

.event-title {
  font-size: 16px;
  width: 90%;
  color: #000;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-title:hover {
  font-size: 16px;
  color: rgb(121, 121, 121);
  font-family: "Kanit", sans-serif;
  align-self: center;
  touch-action: manipulation !important;
  cursor: pointer;
  height: auto;
  white-space: normal !important;
}

.btn-table {
  background-color: #f9a01e !important;
  color: #fff !important;
  border: none !important;
  border-radius: 14px !important;
  margin-right: 10px;
}
.btn-table:hover {
  opacity: 0.3;
}

.btn-event {
  background-color: #40a9ff !important;
  color: #fff !important;
  border: none !important;
  border-radius: 14px !important;
}
.btn-event:hover {
  opacity: 0.3;
}

.eventrow {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 1500px;
  flex-direction: column;
}

.font-amount {
  color: orange;
  font-weight: bold;
  font-size: 53px;
}

.font-baht {
  font-size: 25px;
  font-weight: bold;
  color: rgb(80, 69, 69);
  padding-top: 20px;
  padding-left: 10px;
  font-family: "Kanit", sans-serif;
}
.font-donation {
  color: rgb(170, 170, 170);
  font-weight: 500;
  font-size: 25px;
  font-family: "Kanit", sans-serif;
}
.TEvent {
  width: 790px;
  max-width: 100%;
}

.event-col-pinnedpost {
  padding: 5px !important;
}
.event-col-pinnedpost:hover {
  box-shadow: 1px 1px 5px rgb(179, 179, 179);
  background-color: white;
}

.event-col {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 30px;
  flex-direction: column;
  width: 350px;
  height: 300px;
  margin-right: 12.5px;
  margin-left: 12.5px;
  transition: all 0.5s;
}
.event-col:hover {
  box-shadow: 1px 1px 5px rgb(179, 179, 179);
  background-color: white;
}
.image-slip {
  height: 200px;
  text-align: center;
  width: 100% !important;
}
.crop-image-slip {
  overflow: hidden;
}

.image-slip img {
  width: 100%;
  height: 200px;
  object-position: center;
  object-fit: cover;
  transition: all 0.5s;
}

.image-slip img:hover {
  transform: scale(1.1);
}

.eventCardDetail {
  margin-top: 7px;
  color: #fff;
}

.eventday-img {
  position: relative;
  background-repeat: no-repeat;
  background-size: 60px;
  width: 90%;
  padding: 5px 0px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: gray;
}
.eventCardImg {
  height: 154px;
  width: 208px;
  min-height: unset;
  object-fit: cover;
  object-position: center center;
}

.finish {
  width: 200px !important;
  height: 154px !important;
  box-shadow: none !important;
  background-color: #fafafa;
}
.item:hover .item-overlay.top {
  top: 0;
}
.item-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  overflow: hidden;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  width: 100%;

  -moz-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}
.item-overlay.top {
  top: 100%;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.item {
  position: relative;

  float: left;
  overflow: hidden;
  max-width: 540px;
}
.item img {
  max-width: 100%;
}

.eventCard {
  border-radius: 15px;
  overflow: hidden;
  background: #fafafa !important;
}
.btn-finish {
  padding: 3px 15px;
  text-decoration: none;
  text-align: center;
  border-radius: 21px;
  font-size: 15px;
}

/*................END...............*/
/* ------------------------Footer-------------------------------- */
/*................END...............*/
/*---------------------- responsive------------------------------- */
.eventrow {
  display: flex;
  justify-content: center;
  max-width: 1125px;
}
@media screen and (max-width: 1249px) {
  .eventrow {
    display: flex;
    justify-content: center;
    max-width: 750px;
  }
}
@media only screen and (max-width: 1024px) {
  .event-col {
    flex-direction: column;
  }
}
@media only screen and (max-width: 992px) {
}
@media screen and (max-width: 805px) {
  .font3step {
    font-size: 2px;
    visibility: hidden;
  }
  .pic3step {
    width: 60px;
  }
  .cardact-dt {
    height: 80px;
  }
}
@media (max-width: 782px) {
  .nav-test {
    display: none;
  }
  .nav-test1 {
    display: block !important;
  }
  .header1 {
    height: 30px;
    min-height: 45px;
  }
}
@media only screen and (max-width: 768px) {
  .font-amount {
    color: orange;
    font-weight: bold;
    font-size: 70px;
  }
  .logo-top {
    height: 45px;
    margin-top: 20px;
  }
  .logo {
    height: 85px;
  }
  .pic-vtrun {
    width: 100%;
  }
  .vtrun-zone1 {
    width: 100%;
    margin-left: 0%;
  }
  .vtrun-zone2 {
    padding-top: 0%;
    width: 100%;
    margin-left: 0%;
    word-wrap: break-word;
  }
  .text-vtrun-head {
    font-size: 25px;
  }
  .text-vtrun {
    font-size: 10px;
    text-indent: 40px;
  }
  .eventrow {
    max-width: 100%;
  }
  .event-col {
    margin-left: 1%;
    margin-right: 1%;
    width: 48%;
  }
}
@media screen and (max-width: 700px) {
  .ant-dropdown {
    top: 20px !important;
    position: fixed !important;
  }
  .sctop {
    width: 5%;
  }
}
@media screen and (max-width: 500px) {
  .ant-dropdown {
    top: 24px !important;
    position: fixed !important;
  }
}
@media screen and (max-width: 450px) {
  .pic3step {
    width: 45px;
  }
}
@media screen and (max-width: 425px) {
  .eventrow {
    width: 350px;
  }
  .event-col {
    width: 350px;
    display: flex;
    flex-direction: column;
    margin-left: 0%;
    margin-right: 0%;
  }
  .font-amount {
    color: orange;
    font-weight: bold;
    font-size: 53px;
  }
  .font-baht {
    font-size: 17px;
    padding-top: 20px;
  }

  .header1 {
    height: 50px !important;
    min-height: 40px !important;
    position: fixed;
  }
  .ant-dropdown {
    top: 45px !important;
    position: fixed !important;
  }
  .cardact {
    height: 150px !important;
  }
  .verticalline1 {
    top: 57%;
  }
  .pic-vtrun {
    margin-left: 10%;
    width: 80%;
  }
  .vtrun-zone2 {
    padding-top: 0%;
    width: 100%;
    margin-left: 0%;
    word-wrap: break-word;
  }
  .text-vtrun-head {
    font-size: 18px;
  }
  .text-vtrun {
    font-size: 10px;
    text-indent: 40px;
  }
  .ant-carousel {
    border-top: solid #000 50px;
  }
  .finish {
    width: 168px !important;
    height: 130px !important;
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
  }
}
@media screen and (max-width: 374px) {
  .eventrow {
    width: 300px;
  }
  .event-col {
    width: 300px;
    margin-right: 0;
    margin-left: 0;
  }
}
/*................END...............*/

@media (max-width: 1024px) {
  .nav-test {
    display: none;
  }
}
