.search-box {
  margin-top: 20px !important;
  width: 90% !important;
}
.btn-affect-cumulative {
  background-color: #f9a01b !important;
  color: #fff !important;
  border-color: #f9a01b !important;
}
.btn-affect-cumulative:hover {
  background-color: #ffefd8  !important;
  color: #f9a01b !important;
  border-color: #f9a01b !important;
}
.btn-font-cumulative {
  font-size: 16px;
}
.btn-register {
  color: #f9a01b !important;
  padding-left: '10px' !important;
}
.btn-cancel {
  background-color: #fff !important;
  color: #f9a01b !important;
  border-color: #f9a01b !important;
}
.btn-cancel-remove {
  background-color: #f91a1e !important;
  color: #fff !important;
  border-color: #f91a1e !important;
}
.content {
  display: flex;
  width: 100%;
  padding: 100px 10px 0px 10px;
  justify-content: center;
  max-width: 1250px;
  background-color: #DDDDDD;
  min-height: 100%;
  height: 100%;
}
.content:before {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
}

.content:after {
  content: "";
  display: block;
  height: 75px;
  width: 100%;
}

@media screen and (max-width: 425px){
  .btn-font-cumulative {
    font-size: 10px;
  }
}