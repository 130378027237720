.float-label {
  position: relative;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  font-size: 16px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.45) !important;
  position: absolute;
  pointer-events: none;
  left: 40px;
  top: 30px;
  transition: 0.2s ease all;
}

.label-float {
  top: 8px;
  font-size: 11px;
}
@media screen and (max-width: 1150px) {
  .label {
    font-size: 13px;
  }
}
@media screen and (max-width: 768px) {
  .label {
    font-size: 12px;
  }
}
@media screen and (max-width: 450px) {
  .label {
    font-size: 10px;
    left: 20px;
  }
}